import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouteObject,
} from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LaunchDarklyPageAccess from 'components/LaunchDarklyPageAccess/LaunchDarklyPageAccess';
import { RegistrationFileUpload } from 'components/RegistrationFileUpload/RegistrationFileUpload';
import { RegistrationInfoCard } from 'components/RegistrationInfoCard/RegistrationInfoCard';
import { RegistrationReviewCard } from 'components/RegistrationReviewCard/RegistrationReviewCard';
import { RegistrationSuccessCard } from 'components/RegistrationSuccessCard/RegistrationSuccessCard';
import { RegistrationWaiversCard } from 'components/RegistrationWaiversCard/RegistrationWaiversCard';
import { RequireAuth } from 'lib/auth/RequireAuth';
import { verifyAuth } from 'lib/auth/auth';
import { RegistrationProvider } from 'lib/context/RegistrationContext/RegistrationContext';
import CartSummary from 'domains/Checkout/CartSummary/CartSummary';
import Checkout from 'domains/Checkout/Checkout';
import ConfirmationPage from 'domains/Checkout/ConfirmationPage/ConfirmationPage';
import { MemberRegistration } from 'domains/MemberRegistration/MemberRegistration';
import RosterManagement from 'domains/RosterManagement/RosterManagement';
import ErrorPage from 'domains/Shell/ErrorPage/ErrorPage';
import IFrameContainer from 'domains/Shell/IFrameContainer/IFrameContainer';
import Layout from 'domains/Shell/Layout/Layout';
import SwatchGenerator from 'domains/SwatchGenerator/SwatchGenerator';
import { Registration } from 'domains/Tournaments/Registration/Registration';
import { TournamentDetailsPage } from 'domains/Tournaments/TournamentDetailsPage/TournamentDetailsPage';
import { ActivityFormWrapper } from '../../domains/ActivityForm/ActivityFormWrapper';

export default function useGetRoutePaths() {
  const { ngRosterManagementPageAccess } = useFlags();

  const routeElements = (
    <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
      {/* Protected Paths will not render unless the user is authenticated. */}
      <Route
        path="checkout"
        element={
          <LaunchDarklyPageAccess featureFlagName="cartCheckoutPageAccess">
            <Checkout />
          </LaunchDarklyPageAccess>
        }
        errorElement={<ErrorPage />}
        loader={verifyAuth}
      />
      {ngRosterManagementPageAccess ? (
        <Route
          element={
            <RequireAuth>
              <RegistrationProvider>
                <MemberRegistration />
              </RegistrationProvider>
            </RequireAuth>
          }
          errorElement={<ErrorPage />}
        >
          <Route path="app/registration" element={<RegistrationInfoCard />} />
          <Route
            path="app/registration/waivers"
            element={<RegistrationWaiversCard />}
          />
          <Route
            path="app/registration/upload-documents"
            element={<RegistrationFileUpload />}
          />
          <Route
            path="app/registration/review"
            element={<RegistrationReviewCard />}
          />
          <Route
            path="app/registration/success"
            element={<RegistrationSuccessCard />}
          />
        </Route>
      ) : null}
      <Route
        path="cartSummary"
        element={
          <LaunchDarklyPageAccess featureFlagName="cartCheckoutPageAccess">
            <CartSummary />
          </LaunchDarklyPageAccess>
        }
        errorElement={<ErrorPage />}
        loader={verifyAuth}
      />
      <Route
        path="confirmation"
        element={
          <LaunchDarklyPageAccess featureFlagName="cartCheckoutPageAccess">
            <ConfirmationPage />
          </LaunchDarklyPageAccess>
        }
        errorElement={<ErrorPage />}
        loader={verifyAuth}
      />
      {ngRosterManagementPageAccess ? (
        <Route
          path="app/:programType/:programId/teams/:teamId/roster"
          element={<RosterManagement />}
          errorElement={<ErrorPage />}
          loader={verifyAuth}
        />
      ) : null}
      {/* End Protected Paths */}

      {/* Public Paths */}
      {/* SwatchGenerator is here temporarily until it can be moved to
       Storybook -DC 4/6/23 */}
      <Route
        path="swatchGenerator"
        element={<SwatchGenerator />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="app/clubteams/:subProgramId/teams/:teamId/activity/:activityId?"
        element={
          <RequireAuth>
            <ActivityFormWrapper />
          </RequireAuth>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="app/tournaments/:tournamentId"
        element={<TournamentDetailsPage />}
        errorElement={<ErrorPage />}
      />
      <Route index element={<IFrameContainer />} />
      <Route
        path="app/tournaments/:tournamentId/registration"
        element={
          <RequireAuth>
            <Registration />
          </RequireAuth>
        }
        errorElement={<ErrorPage />}
      />
      <Route path="*" element={<IFrameContainer />} />
      {/* End Public Paths */}
    </Route>
  );

  const routesConfig: RouteObject[] = createRoutesFromElements(routeElements);

  return createBrowserRouter(routesConfig);
}
